@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.hz-SellerHeader {
  position: relative;
  @include hz-textStyle--bodyRegular;
}

.profile-head-tab {
  text-align: center;

  @include hz-breakpoint-s-up {
    text-align: left;
  }
}

.follow-user-tooltip {
  position: absolute;
  width: 300px;
  left: 12px;
  bottom: 32px;

  &.withTabs {
    bottom: 72px;
  }

  &.hz-Alert.hz-Alert--point-bottom:before {
    left: 10%;
  }

  @include hz-breakpoint-s-up {
    left: auto;
    right: 12px;
    bottom: 32px;

    &.withTabs {
      bottom: 82px;
    }

    &.hz-Alert.hz-Alert--point-bottom:before {
      left: 90%;
    }
  }
}
