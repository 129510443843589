@import "~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars";
@import "~@hz-design-system/design-tokens/dist/web/global/ThemeGetters";
@import "~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints";

.hz-ViewControl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: $hz-spacing--s 0;

  @include hz-breakpoint-m-up {
    flex-wrap: unset;
  }

  .hz-Dropdown-formControl {
    border: none;
    @include hz-breakpoint-m-up {
      border: 1px solid $hz-color--borderMedium;
    }
  }

  .hz-Dropdown-input {
    opacity: 0;

    @include hz-breakpoint-s-up {
      opacity: 1;
    }
  }

  &-sortByLabel {
    @include hz-textStyle--bodyRegular;
    margin-right: $hz-spacing--xxxs;
  }

  .view-option-label {
    display: none;

    @include hz-breakpoint-s-up {
      display: inline;
    }
  }

  &-group {
    align-items: center;
    display: flex;

    &--view-options {
      display: flex;

      @include hz-breakpoint-m-up {
        flex-grow: 1;
      }

      button.hz-Button--tertiary {
        &:disabled {
          background-color: $hz-color--buttonTertiaryHoverInverse;
          color: $hz-color--buttonTertiaryHover;
        }
      }

      .isActive {
        background-color: $hz-color--buttonTertiaryHoverInverse;
      }
    }

    &--functional-options {
      flex: 1;
      justify-content: center;
      position: relative;

      &:nth-child(3) {
        display: flex;
      }

      select {
        height: 22px !important;
      }

      .hz-ViewControl-sortByLabel {
        display: none;
      }

      button {
        @include hz-textStyle--bodyRegular;
        padding: 0;
        border: 0;
        background-color: transparent !important;
        box-shadow: none !important;
        vertical-align: middle;
        min-height: 0;
        display: flex;
        align-items: center;

        .hz-Button-icon {
          width: 24px;
          height: 24px;
          margin: 0 $hz-spacing--xxxs 0 0;
        }
      }

      .hz-ViewControl-sort-button {
        position: absolute;
        width: 100%;
        pointer-events: none;
        background: $hz-color--headerWebSearchBarBackground !important;
        display: flex;
        justify-content: center;
      }

      @include hz-breakpoint-m-up {
        .hz-hide-desktop {
          display: none;
        }

        flex: unset;

        select {
          height: unset !important;
        }

        .hz-ViewControl-sortByLabel {
          display: inline-block;
        }

        button {
          display: none;
        }

        .hz-ViewControl-sort-button {
          display: none;
        }
      }
    }
  }
}
