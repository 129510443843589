@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '../../../styles/fallback-variables';

$carousel-image-height: 200px;

.hz-Listing--gallery-item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: $hz-spacing--xxxxs;
  max-width: 48%;
  min-width: 48%;
  overflow: hidden;

  a {
    height: 100%;
  }

  @include hz-breakpoint-m-up {
    max-width: 32%;
    min-width: 32%;
  }

  @include hz-breakpoint-xl-up {
    max-width: 24%;
    min-width: 24%;
    margin: 0.5%;
  }

  .hz-Carousel-button {
    display: none;
  }

  &:hover {
    box-shadow: 0 4px 8px 0 $hz-color--overlayDefault;
    transform: translateY(-1px);
    transition: box-shadow 0.25s ease-in-out, transform 0.25s ease-in-out;

    .hz-Carousel-button {
      display: block;
    }
  }

  .hz-Listing-select-container {
    margin-bottom: 15px;
  }

  .hz-Listing-image-container {
    background: $hz-color--backgroundLight;
    height: 200px;
    margin: calc($hz-spacing--xxs * -1);
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;

    a {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
    }

    .hz-Listing-image-none-item {
      @include hz-textStyle--bodySmall;
      color: $hz-color--borderMedium;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      opacity: 0.4;
      @include hz-textStyle--bodyLarge;
    }

    .hz-Listing-image-item--carousel {
      display: flex;
      align-items: center;
      height: $carousel-image-height;
      position: relative;

      img {
        object-fit: contain;
      }
    }
  }

  .hz-Listing-content {
    flex: 1 0 auto;
    justify-content: space-between;
    margin-top: $hz-spacing--s;
  }

  .hz-Listing-location {
    .hz-Icon.hz-SvgIcon {
      width: $hz-spacing--m;
      height: $hz-spacing--m;
      margin-left: -3px;
    }
  }

  .hz-Listing-date {
    i {
      margin-left: -2px;
    }
  }

  .hz-Listing-location,
  .hz-Listing-date {
    @include hz-textStyle--bodySmall;
    color: $hz-color--borderStrong;
    display: flex;
    align-items: center;
    padding: 0 $hz-spacing--xxxxs;
  }

  .hz-Listing-seller-link {
    & a {
      font-size: small;
      display: flex;
      align-items: center;
      width: -moz-fit-content;
      //noinspection CssInvalidPropertyValue
      width: -webkit-fit-content;
      width: fit-content;
    }
  }

  .hz-Listing-priority {
    margin: $hz-spacing--xxxs 0;
    @include hz-textStyle--bodySmall;

    @include hz-breakpoint-m-up {
      @include hz-textStyle--bodyRegular;
    }
  }

  .hz-Listing-price {
    @include hz-textStyle--title5;
    font-size: 16px;
  }

  .hz-Listing-title {
    height: 43px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    @include hz-textStyle--bodyRegular;

    @include hz-breakpoint-m-up {
      @include hz-textStyle--bodyLarge;
    }
  }
}
