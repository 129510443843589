@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.hz-Pills {
  position: static;
  background-color: $hz-color--backgroundLight;
  z-index: 9;
  top: 122px;

  & > h3 {
    @include hz-textStyle--title5;
  }

  .deletePills {
    display: none;

    @include hz-breakpoint-m-up {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-left: 0;
      margin-top: 0;
      padding-left: $hz-spacing--xs;
    }
  }

  & .hz-Chip-icon {
    cursor: pointer;
  }

  .pillsContainer {
    align-items: center;
    display: flex;

    @include hz-breakpoint-m-up {
      flex-direction: row;
      justify-content: flex-start;
    }

    .hz-Pill {
      margin-top: 0;
    }
  }

  .chipsSlider {
    overflow: hidden;

    .innerWrapper {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent; /* Chrome/Safari/Webkit */
      }

      > button,
      > a {
        scroll-snap-align: start;
        flex-shrink: 0;
        position: relative;
      }

      > a {
        margin-right: $hz-spacing--xs;
      }
    }
  }

  @include hz-breakpoint-m-up {
    padding: 10px 10px 5px 0;
  }
}

.stickyPills {
  @include hz-breakpoint-m-up {
    position: sticky;
  }
}

.hz-Chips {
  margin-bottom: 10px;

  & > h3 {
    @include hz-textStyle--title5;
  }

  &--sticky {
    position: static;
    background-color: $hz-color--backgroundPressed;
    z-index: 9;
    top: 122px;
  }

  & .hz-Chip-icon {
    cursor: pointer;
  }

  @include hz-breakpoint-m-up {
    position: sticky;
    padding: 10px 10px 5px 0;
  }
}

.chipsRemoveAll {
  cursor: pointer;
}

.PillPrimary {
  background-color: $hz-color--signalActionDefault;
  border-color: $hz-color--signalActionDefault;
  color: $hz-color--textPrimaryInverse;

  &:hover {
    background-color: $hz-color--signalActionHover;
    border-color: $hz-color--signalActionHover;

    .hz-Icon {
      filter: invert(100%) sepia(22%) saturate(1%) hue-rotate(166deg) brightness(108%) contrast(97%);
    }
  }
}

.allFiltersPill {
  flex-shrink: 0;

  @include hz-breakpoint-up(l) {
    display: none;
  }
}

.lrp-cars-filter-dialog .hz-Modal-Footer--desktopStretched .hz-Button {
  flex-basis: 100%;
}

.counterNoScroll .hz-Pills .chipsSlider .innerWrapper {
  overflow: visible;
}
