@import "~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars";
@import "~@hz-design-system/design-tokens/dist/web/global/ThemeGetters";
@import "~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints";

.hz-TopSection-Backdrop {
  min-height: 118px;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0 0 36.73469387755102% 0;

  @include hz-breakpoint-s-up {
    min-height: 190px;
  }

  @include hz-breakpoint-xl-up {
    padding: 0;
    min-height: 367px;
  }

  &--stretchy {
    background-size: cover;
    background: $hz-color--backgroundPressed no-repeat center center;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    @include hz-breakpoint-xl-up {
      background-size: cover;
    }
  }
}

.hz-TopSection {
  background-color: $hz-color--textPrimary;
  min-height: 52px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include hz-breakpoint-s-up {
    min-height: 76px;
  }
}

.hz-TopSection-image {
  align-items: center;
  background-color: $hz-color--backgroundDefault;
  border-radius: 3px;
  border: solid 2px $hz-color--backgroundDefault;
  bottom: 10px;
  box-shadow: 2px 2px 4px 0 rgba(255, 255, 255, 0.4);
  display: flex;
  height: 72px;
  justify-content: center;
  left: 16px;
  position: absolute;
  width: 72px;

  img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 3px;
  }

  &-placeholder {
    height: 50px;
    opacity: 0.6;
    width: 50px;
  }

  @include hz-breakpoint-s-up {
    bottom: 20px;
    height: 150px;
    left: 46px;
    width: 150px;

    &-placeholder {
      height: 100px;
      width: 100px;
    }
  }
}

.hz-TopSection-TitleWrap {
  bottom: 10px;
  left: 96px;
  position: absolute;

  &-Name {
    @include hz-textStyle--title3;

    @include hz-breakpoint-s-up {
      @include hz-textStyle--title2;
    }
  }

  &-Slogan {
    margin-top: 4px;

    @include hz-breakpoint-s-up {
      @include hz-textStyle--title5;
      margin-top: 16px;
    }
  }

  &-Name,
  &-Slogan {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    color: $hz-color--backgroundDefault;
  }

  @include hz-breakpoint-s-up {
    bottom: 20px;
    left: 46px + 150px + 20px;
  }
}

.seller-header {
  &__with-no-imagery-top-section {
    background-color: $hz-color--textPrimary;
    min-height: 52px;
    display: flex;
    align-items: center;

    @include hz-breakpoint-s-up {
      min-height: 80px;
    }
    
    .title {
      @include hz-textStyle--title3;
      color: $hz-color--textPrimaryInverse;
      margin-left: $hz-spacing--xs;

      @include hz-breakpoint-m-up {
        margin-left: $hz-spacing--xl;
      }
    }
  }
}
