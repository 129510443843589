@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '../../../styles/fallback-variables';

$content-width-mobile: 130px;
$content-width-tablet: 145px;
$content-width-desktop: 223px;

.hz-Listing {
  &--list-item {
    display: flex;
    overflow: hidden;
    position: relative;
    padding: $hz-spacing--xxs;
    border-radius: $hz-spacing--xxs;
    margin-bottom: $hz-spacing--xxs;
    flex-direction: row;

    @include hz-breakpoint-s-up {
      padding: $hz-spacing--xs;
      margin: $hz-spacing--xs 0;
    }

    @include hz-breakpoint-m-up {
      padding: $hz-spacing--s;
      flex-direction: row;
    }

    .hz-Listing-listview-content {
      width: 100%;
    }

    .hz-Listing-item-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      position: relative;

      @include hz-breakpoint-m-up {
        flex-direction: row;
      }
    }

    .hz-Carousel-button {
      display: none;
    }

    &:hover {
      .hz-Carousel-button {
        display: block;
      }
    }

    .hz-Listing-select-container {
      align-items: center;
      display: flex;
      flex: 0 1 20px;
      justify-content: center;
    }

    .hz-Listing-location-icon {
      display: none;
    }

    .hz-Listing-image-container {
      display: flex;
      flex-direction: column;
      margin: 0;
      position: relative;
      max-width: $content-width-mobile;
      min-width: $content-width-mobile;
      min-height: 77px;
      justify-content: flex-start;

      @include hz-breakpoint-m-up {
        max-width: $content-width-tablet;
        min-width: $content-width-tablet;
        max-height: 194px;
        min-height: 108px;
      }

      @include hz-breakpoint-xl-up {
        max-width: $content-width-desktop;
        min-width: $content-width-desktop;
        max-height: 283px;
        min-height: 167px;
      }

      .hz-Listing-sub-images {
        display: flex;
        flex-direction: row;
        margin-top: $hz-spacing--xxxs;
        flex: 0;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;

        .hz-Listing-image-item {
          flex: 0 1 50%;
          max-width: 50%;
          text-align: center;
          position: relative;
          max-height: 63px;

          @include hz-breakpoint-m-up {
            height: 100%;
            width: 100%;
            max-width: 70px;
            min-width: 70px;
            max-height: 52px;
            min-height: 52px;
          }

          @include hz-breakpoint-xl-up {
            max-width: 109px;
            min-width: 109px;
            max-height: 82px;
            min-height: 82px;
          }

          &:not(:last-child) {
            margin-right: $hz-spacing--xxxs;
          }

          &:nth-child(1) {
            padding-right: 0;
          }

          &:nth-child(2) {
            padding-left: $hz-spacing--xxxxs;
            padding-left: 0;
          }

          img {
            border-radius: $hz-spacing--xxxs;
            height: auto;

            @include hz-breakpoint-m-up {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }

        &--details-open {
          flex: 0;
        }
      }

      .hz-Listing-image-item {
        box-sizing: border-box;
        text-align: center;
        border-radius: $hz-spacing--xxxs;
        overflow: hidden;

        @include hz-breakpoint-s-up {
          display: block;
        }

        &--main {
          align-items: center;
          display: flex;
          justify-content: center;
          overflow: hidden;
          position: relative;
          text-align: center;
          max-width: $content-width-mobile;
          min-width: $content-width-mobile;

          @include hz-breakpoint-m-up {
            max-width: $content-width-tablet;
            min-width: $content-width-tablet;
            max-height: 108px;
            min-height: 108px;
          }

          @include hz-breakpoint-xl-up {
            max-width: $content-width-desktop;
            min-width: $content-width-desktop;
            max-height: 167px;
            min-height: 167px;
          }
        }

        &::before {
          display: none;
        }

        img {
          max-height: 100%;
          max-width: 100%;
          min-height: 0;
          min-width: 0;
          object-fit: contain;
          z-index: 1;
          border-radius: $hz-spacing--xxxs;
          height: auto;

          @include hz-breakpoint-m-up {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      &--details-open {
        justify-content: flex-start;
      }
    }

    .hz-Listing-seller-name-container {
      @include hz-textStyle--bodyRegular;
      margin-bottom: $hz-spacing--xxs;

      @include hz-breakpoint-m-up {
        @include hz-textStyle--bodyLargeStrong;
      }
    }

    .hz-Listing-coverLink {
      display: flex;
      flex: 1;
      flex-direction: row;
      z-index: 2;

      &--noop {
        pointer-events: none;
      }
    }

    .hz-Listing-title {
      margin: -1px 0 0;
      @include hz-textStyle--bodyRegularStrong;
      color: $hz-color--signalInfoDefault;

      @include hz-breakpoint-m-up {
        @include hz-textStyle--bodyLargeStrong;
        min-height: 24px;
        margin-top: $hz-spacing--xxxxs;
      }
    }

    .hz-Listing-price {
      @include hz-textStyle--title5;
      text-align: left;

      &--mobile {
        display: block;
        margin-top: $hz-spacing--xs;

        @include hz-breakpoint-l-up {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include hz-breakpoint-l-up {
          display: block;
        }
      }
    }

    .hz-Listing-group {
      display: flex;
      margin-left: 8px;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      margin-right: 0;
      margin-bottom: 38px;

      @include hz-breakpoint-m-up {
        flex-direction: row;
        margin-left: $hz-spacing--xs;
        margin-right: $hz-spacing--s;
        margin-bottom: 0;
      }

      &--title-description-attributes {
        flex-direction: column;

        @include hz-breakpoint-m-up {
          flex-basis: 100%;
          flex-grow: 1;
          display: flex;
        }
      }

      &--price-date-feature {
        max-width: 100%;
        flex-grow: 1;
        display: none;
        justify-content: start;

        @include hz-breakpoint-m-up {
          align-items: flex-end;
          flex-grow: 0;
          flex-direction: column;
          flex-basis: 50%;

          .hz-Listing-priority--all-devices {
            display: block;
          }
        }

        @include hz-breakpoint-l-up {
          display: flex;
        }

        &--tablet {
          display: flex;
          align-items: flex-start;

          @include hz-breakpoint-l-up {
            display: none;
          }

          .hz-Listing-date {
            text-align: left;
          }
        }

        &--details-open {
          flex-grow: 0;

          .hz-Listing-priority--all-devices {
            display: none;
          }
        }
      }

      &--mobile-bottom-row {
        display: flex;
        flex-flow: row;
        flex-grow: 0;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 139px;
        right: 0;

        @include hz-breakpoint-m-up {
          display: none;
        }

        > div {
          display: flex;
          flex-flow: column wrap;
          justify-content: flex-end;
          align-items: flex-end;

          &:first-child {
            align-items: flex-start;
          }
        }

        .hz-bottom-left {
          flex-grow: 1;
        }

        .hz-bottom-right {
          text-align: right;
        }
      }
    }

    .hz-Listing-title,
    .hz-Listing-description {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      hyphens: auto;
    }

    .hz-Listing-description {
      display: none;
      @include hz-textStyle--bodySmall;
      color: $hz-color--textSecondary;
      margin-top: $hz-spacing--xxs;

      @include hz-breakpoint-s-up {
        @include hz-textStyle--bodyRegular;
        display: -webkit-box;
      }

      @include hz-breakpoint-m-up {
        margin-top: $hz-spacing--xxxs;
      }

      @include hz-breakpoint-l-up {
        margin-bottom: $hz-spacing--xxs;
      }
      &--extended {
        display: block;
      }
    }

    .hz-Listing--sellerInfo {
      display: none;

      @include hz-breakpoint-m-up {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 19%;
        margin-right: 0;

        span {
          box-sizing: border-box;
        }

        .hz-Listing-distance {
          display: inline-block;
        }
      }
    }

    .hz-Listing-seller-name {
      margin-top: 2px;

      .hz-Icon {
        margin-top: -5px;
      }
    }

    .hz-Listing-seller-link {
      width: 85%;
      pointer-events: auto;
      margin-top: hz-spacing(xxs);

      @include hz-breakpoint-l-up {
        margin-top: auto;
      }

      @include hz-breakpoint-xl-up {
        padding-top: hz-spacing(xxxs);
      }

      & a {
        @include hz-textStyle--bodySmall;
        width: fit-content;

        @include hz-breakpoint-m-up {
          @include hz-textStyle--bodyRegular;
        }
      }
    }

    .hz-Listing-seller-external-link {
      z-index: 2;

      @include hz-breakpoint-m-up {
        display: none;
      }
    }

    .hz-Listing-sellerCoverLink {
      display: flex;
      pointer-events: auto;
      flex: 0 1 15%;
    }

    .hz-Listing-priority {
      align-self: flex-end;
      margin-bottom: $hz-spacing--xxxs;
      @include hz-textStyle--bodySmall;
      display: flex;
      margin-top: 0;

      @include hz-breakpoint-m-up {
        @include hz-textStyle--bodyRegular;
        margin-bottom: 0;
        display: none;
        margin-top: auto;
      }

      @include hz-breakpoint-xl-up {
        padding-top: hz-spacing(xxs);
      }
    }

    .hz-Attribute {
      margin-right: $hz-spacing--xxxs;

      @include hz-textStyle--bodySmall;
      padding: 0;
      color: $hz-color--textSecondary;
      display: block;

      &--default {
        display: inline-block;
        @include hz-textStyle--bodyRegularStrong;
        color: $hz-color--textPrimary;
        padding: 0;

        &:not(:last-child):after {
          content: "\B7";
          padding: 0 4px;
        }
      }

      &--nap {
        @include hz-textStyle--bodySmall;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $hz-color--textPrimary;

        @include hz-breakpoint-s-up {
          display: inline-block;
        }

        &:not(:last-child):after {
          padding: 0 4px;
        }
      }

      &--micro-tip {
        @include hz-textStyle--bodySmallStrong;
        background: $hz-color--signalActionDefault;
        padding: $hz-spacing--xxxs $hz-spacing--xxs;
        border-radius: $hz-spacing--xxxs;
        margin-bottom: $hz-spacing--xxs;
        color: $hz-color--backgroundDefault;
        display: inline-block;

        &:after {
          content: unset !important;
        }

        @include hz-breakpoint-s-up {
          @include hz-textStyle--bodyRegularStrong;
          width: fit-content;
        }

        @include hz-breakpoint-l-up {
          margin-bottom: $hz-spacing--xs;
        }

        @include hz-breakpoint-xl-up {
          margin-bottom: 0;
        }
      }

      &--trust-item {
        @include hz-textStyle--bodySmall;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $hz-color--textPrimary;
      }
    }

    .hz-Listing-refurbished-item {
      display: none;

      i {
        margin-right: $hz-spacing--xxxs ;
        margin-top: 0;
      }

     &-details {
       display: flex;
       color: $hz-color--signalSuccessDefault;
       background-color: $hz-color--signalSuccessDefaultInverse ;
       padding: $hz-spacing--xxxs $hz-spacing--xxs;
       width: fit-content;
       border-radius: $hz-spacing--xxxs;
       align-items: center;
       justify-content: center;

       @include hz-breakpoint-m-up {
         display: none;
       }
     }

     @include hz-breakpoint-m-up {
        display: flex;
        color: $hz-color--signalSuccessDefault;
        background-color: $hz-color--signalSuccessDefaultInverse ;
        padding: $hz-spacing--xxxs $hz-spacing--xxs;
        width: fit-content;
        border-radius: $hz-spacing--xxxs;
        align-items: center;
        justify-content: center;
        margin-top: $hz-spacing--xxs;
      }

      @include hz-breakpoint-l-up {
        margin-bottom: $hz-spacing--xs;
        margin-top: 0;
      }

      @include hz-breakpoint-xl-up {
        margin-bottom: 0;
      }
    }

   .hz-Listing-trust-items-and-attributes {
      @include hz-breakpoint-s-up {
        margin: $hz-spacing--xxs 0;
      }

      @include hz-breakpoint-m-up {
        margin-top: auto;
        margin-bottom: 0;
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .hz-Listing-bottom-info-divider-wrapper {
      display: none;

      @include hz-breakpoint-l-up {
        display: block;
        height: 30px;
      }
    }

    .hz-Listing-bottom-info-divider {
      @include hz-breakpoint-l-up {
        width: 100%;
        position: absolute;
        margin: 0;
        bottom: 30px;
      }
    }

    .hz-Listing-trust-items {
      margin-bottom: hz-spacing(xxxs);

      @include hz-breakpoint-m-up {
        margin: hz-spacing(xxs) 0 0;
      }

      @include hz-breakpoint-l-up {
        margin-bottom: hz-spacing(xxs);
      }

      @include hz-breakpoint-xl-up {
        margin-bottom: hz-spacing(xs);
      }
    }

    .hz-Listing-trust-item {
      margin: 0 hz-spacing(xxxs) hz-spacing(xxxs) 0;
    }

    .hz-Listing-price-extended-details {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    .hz-Listing-attributes {
      display: flex;
      align-items: center;

      @include hz-breakpoint-s-up {
        display: flex;
      }

      @include hz-breakpoint-m-up {
        margin: hz-spacing(xxs) 0 0;
      }

      @include hz-breakpoint-xl-up {
        padding-top: hz-spacing(xxxs);
      }
    }

    .hz-Listing-extended-attributes {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;

      @include hz-breakpoint-s-up {
        display: flex;
      }

      @include hz-breakpoint-m-up {
        margin: hz-spacing(xxs) 0 0;
      }

      @include hz-breakpoint-xl-up {
        padding-top: hz-spacing(xxxs);
      }

      .hz-Attribute:not(:last-child):after {
        content: "\7C";
      }
    }

    .hz-Listing-attributes-nap-mileage {
      &--mobile {
        display: block;

        @include hz-breakpoint-s-up {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include hz-breakpoint-s-up {
          display: block;
        }
      }
    }

    .hz-Listing-seller-other-items-divider {
      margin: hz-spacing(xxs) calc(hz-spacing(m) * -1);

      @include hz-breakpoint-m-up {
        margin: hz-spacing(xs) calc(hz-spacing(m) * -1);
      }

      @include hz-breakpoint-xl-up {
        margin: hz-spacing(s) calc(hz-spacing(m) * -1);
      }
    }

    .hz-text-price-label {
      margin-bottom: $hz-spacing--xxs;
    }

    .hz-Listing-image-none-item--main {
      width: 100%;
      height: 100%;
      background: $hz-color--backgroundLight;
      border-radius: $hz-spacing--xxxs;
      justify-content: center;
    }
  }

  &-description {
    margin-top: 2px;
    color: $hz-color--textPrimary;
    word-break: break-word;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    display: none;



    @include hz-breakpoint-s-up {
      display: block;
      @include hz-textStyle--bodyRegular;
      color: $hz-color--textSecondary;
      margin-top: $hz-spacing--xxs;
    }
  }

  &-attributes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;

    @include hz-breakpoint-m-up {
      margin-top: auto;
    }

    &-carpass {
      margin-top: 9px;
      vertical-align: bottom;
      /* Overwrites styles from hz-web-ui for fluid asset */
      height: 16px !important;
      width: 100px !important;
      background-repeat: no-repeat !important;
    }
  }

  &-seller-logo {
    display: none;

    @include hz-breakpoint-m-up {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: $hz-spacing--xxs;

      img {
        border-radius: 5px;
        max-width: 80px;
        max-height: 80px;
      }
    }
  }

  &-date {
    text-align: right;

    &--desktop {
      display: none;

      @include hz-breakpoint-m-up {
        display: block;
      }
    }
  }

  &-distance {
    @include hz-textStyle--bodySmall;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: $hz-color--textSecondary;

    @include hz-breakpoint-m-up {
      @include hz-textStyle--bodyRegular;
    }
  }

  &-date {
    @include hz-textStyle--bodySmall;
    color: $hz-color--textSecondary;
    width: 100%;

    @include hz-breakpoint-s-up {
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include hz-breakpoint-m-up {
      @include hz-textStyle--bodyRegular;
      margin-bottom: $hz-spacing--xxs;
    }
  }

  &-location {
    padding-top: 0;
    width: auto;
    margin-top: hz-spacing(xxxs);
    max-width: 116px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $hz-color--textSecondary;

    @include hz-breakpoint-m-up {
      @include hz-textStyle--bodyRegular;
      margin-top: 0;
      max-width: 155px;
      margin-bottom: hz-spacing(xxs);
    }

    .hz-Listing-distance-label {
      color: $hz-color--textSecondary;

      @include hz-breakpoint-m-up {
        display: inline-block;
        margin-bottom: hz-spacing(xxs);
      }
    }

    .hz-Listing-distance {
      @include hz-breakpoint-m-up {
        display: inline-block;
      }
    }
  }

  &-favorite-button {
    position: absolute;
    top: $hz-spacing--xxxs;
    right: $hz-spacing--xxxs;
    z-index: 2;
  }
}

.hz-Listing-image-none-item {
  &--main {
    align-items: center;
    display: flex;
    flex-direction: column;

    .hz-Text {
      color: $hz-color--textTertiary;
    }
  }
}
