@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.hz-Toast-root {
  bottom: 0;
  padding: $hz-spacing--s $hz-spacing--xs;
  z-index: $hz-zIndex--notifications;

  @include hz-breakpoint-m-up {
    margin: $hz-spacing--s;
    padding: 0;
    width: 336px;
  }
}

.hz-Toast-body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.hz-Toast-message {
  border-radius: 0;
  box-shadow: unset;
  margin-bottom: 0;
  min-height: auto;
  padding: 0;
}

.hz-Toast-message + .hz-Toast-message {
  margin-top: $hz-spacing--s;
}

.hz-Toast-progressBar {
  background: none;
}
