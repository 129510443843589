@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

$content-height: 60px;
$content-width-mobile: 130px;
$content-width-tablet: 145px;
$content-width-desktop: 223px;

.hz-Listing-seller-other {
  display: flex;
  justify-content: space-between;

  @include hz-breakpoint-s-up {
    justify-content: flex-start;
  }

  .hz-Listing-seller-other {
    &-content {
      &--left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: $content-width-mobile;

        @include hz-breakpoint-m-up {
          width: $content-width-tablet;
        }

        @include hz-breakpoint-xl-up {
          width: $content-width-desktop;
        }
      }

      &--paragraph {
        @include hz-textStyle--bodyRegular;
        color: $hz-color--textSecondary;
      }

      &--all-items-link {
        display: none;

        @include hz-breakpoint-m-up {
          display: block;
          @include hz-textStyle--bodyRegular;
          color: $hz-color--signalLinkDefault;
          margin-top: $hz-spacing--xxs;
          text-decoration: none;
        }
      }

      &--right {
        display: flex;

        @include hz-breakpoint-s-up {
          margin-left: $hz-spacing--xxs;
        }

        @include hz-breakpoint-m-up {
          margin-left: $hz-spacing--xs;
        }

        .hz-Listing-seller-other-item {
          display: none;

          &:nth-child(-n + 3) {
            display: flex;
          }

          @include hz-breakpoint-m-up {
            &:nth-child(-n + 4) {
              display: flex;
            }
          }

          @include hz-breakpoint-xl-up {
            &:nth-child(-n + 2) {
              display: flex;
            }

            &:nth-child(n + 3) {
              display: none;
            }
          }
        }

        .hz-text-price-label {
          @include hz-textStyle--title5;
          color: $hz-color--textPrimary;
        }
      }

      &--item-title {
        @include hz-textStyle--bodyRegularStrong;
        color: $hz-color--signalActionDefault;
      }
    }

    &-title-and-price {
      display: none;

      @include hz-breakpoint-xl-up {
        display: block;
        max-width: $content-width-desktop;
        margin-right: $hz-spacing--s;
      }
    }

    &-image-container {
      margin-right: $hz-spacing--xxs;

      @include hz-breakpoint-m-up {
        margin-right: $hz-spacing--xs;
      }

      @include hz-breakpoint-xl-up {
        margin-right: $hz-spacing--s;
      }

      img {
        border-radius: $hz-spacing--xxxs;
        width: 50px;
        height: 37px;
        object-fit: cover;

        @include hz-breakpoint-s-up {
          width: 103px;
          height: 77px;
        }
      }
    }
  }
}

// animation logic only for large screen
@include hz-breakpoint-l-up {
  .hz-Listing-other-seller-content--center:hover
    .hz-Listing-other-seller-items:hover
    .hz-Listing-other-seller-info-container,
  .hz-Listing-other-seller-content--center
    .hz-Listing-other-seller-items--default
    .hz-Listing-other-seller-info-container {
    width: 205px;
  }
}

.hz-Listing-other-seller-info-container,
.hz-Listing-other-seller-content--center:hover
  .hz-Listing-other-seller-info-container {
  width: 0;
  overflow: hidden;
  transition: width 0.25s;
}
