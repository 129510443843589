@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

#hz-HubPage-container {
  margin: $hz-spacing--xxs 0;

  .hz-Block {
    flex-direction: row;
    align-items: center;
    justify-content: center;

    i {
      margin-bottom: -6px;
    }
  }

  .hz-Card {
    box-shadow: none;
  }

  .hz-HubPages {
    &-title {
      @include hz-textStyle--title5;
      display: inline-block;
    }

    &-content {
      & h1 {
        @include hz-textStyle--title3;
      }
      & h2 {
        @include hz-textStyle--title5;
        margin: $hz-spacing--xs 0;
      }
      & h3 {
        @include hz-textStyle--bodyLarge;
        margin: $hz-spacing--xs 0;
      }
      & h4 {
        @include hz-textStyle--bodyLarge;
        margin: calc($hz-spacing--m - 4px) 0 0 0;
      }

      & ul > li {
        @include hz-textStyle--bodyRegular;
      }

      & p {
        @include hz-textStyle--bodyRegular;
        margin: 0;
      }
    }
  }
}
