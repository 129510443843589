@import "~@hz-design-system/design-tokens/dist/web/global/tokens/ZIndexes.scss";
@import "normalize.css/normalize.css";
@import "@/client/containers/pages/LRPContainer.scss";
@import "@/client/components/Banner/Banner.scss";
@import '@/client/components/Breadcrumb/Breadcrumb.scss';
@import '@/client/components/FilterChips/FilterChips.scss';
@import '@/client/components/FilterSidebar/FilterSidebar.scss';
@import '@/client/components/HubPages/HubPages.scss';
@import '@/client/components/Listings/styles/index.scss';
@import '@/client/components/Listings/atoms/ListingDetailsButton.scss';
@import '@/client/components/Listings/atoms/ListingEnergyLabel.scss';
@import '@/client/components/Listings/atoms/ListingOpvalSticker.scss';
@import '@/client/components/Listings/atoms/ListingSellerName.scss';
@import '@/client/components/Listings/molecules/ListingListViewContent.scss';
@import '@/client/components/Listings/molecules/ListingListViewContentCars.scss';
@import '@/client/components/Listings/molecules/ListingOtherSeller.scss';
@import '@/client/components/PaginationControls/PaginationControls.scss';
@import '@/client/components/FaqFooter/FaqFooter.scss';
@import '@/client/components/SellerHeader/Info/index.scss';
@import '@/client/components/SellerHeader/ReviewsDialog/index.scss';
@import '@/client/components/ZRPSearchHelper/ZRPSearchHelper.scss';
@import '@/client/components/ViewControls/ViewControl.scss';
@import '@/client/components/Toast/ToastNotification.scss';
@import '@/client/components/SuggestedSearches/SuggestedSearches.scss';
@import '@/client/components/Seo/DataDrivenContent.scss';
@import '@/client/components/SellerHeader/index.scss';
@import '@/client/components/SellerHeader/TopSection/index.scss';
@import '@/client/components/SSOIZeroResults/SSOIZeroResultsBox.scss';
@import '@/client/components/Dac7PromoBanner/Dac7PromoBanner.scss';
@import '@/client/components/LinkGenerator/smartBanners.scss';
@import '@/client/components/molecules/SaveSearchContainer.scss';
@import '@/client/components/NDFCGenericRankingsText/NDFCGenericRankingsText.scss';
@import '@/client/components/ShippingCampaignBanner/ShippingCampaignBanner.scss';
@import '@/client/components/SmartBanner/SmartBannerLarge.scss';
@import '@/HOC/components/ErrorPages/ErrorPage.scss';
@import '@/client/components/FaqReporting/styles/FaqReporting.scss';

.hz-Page-body--lrp {
  justify-content: space-between;
}

.u-resetFooter {
  position: relative;
  z-index: getZIndex(header);
}

/* TODO migrate to hz-design-tokens, this is copied directly from design-sdk*/
$mp-font-base--light: 'Arial' !default;
$mp-font-base-light-family: $mp-font-base--light, sans-serif !default;

@mixin mp-font-base-light {
  font-family: $mp-font-base-light-family;
  font-style: normal;
  font-weight: 300;
}

@mixin mp-font-base-medium {
  font-family: $mp-font-base-light-family;
  font-style: normal;
  font-weight: 500;
}

.hz-hidden {
  display: none!important;
}

/* TODO migrate to hz-design-tokens*/
