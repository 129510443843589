@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '../../styles/fallback-variables';

/** Fixes screen flickering when scrolling fast **/
body {
  background: $hz-color--backgroundPressed;

  @include hz-breakpoint-xs-up {
    .counterNoScroll {
      overflow: auto;
    }
  }
}

.hz-Page-body {
  flex-wrap: nowrap;
}

.hz-Page-container {
  width: 100%;
  position: relative;

  &--fullHeight {
    min-height: 100vh;
  }
}

.hz-Page-content {

  @include hz-breakpoint-xl-up {
    max-width: 1200px;
  }

  @media screen and (min-width: 1320px) {
    max-width: 1320px;
  }

  @media screen and (min-width: 1480px) {
    max-width: 1480px;
  }
}

.hz-Alert--info.hz-Alert--point-customLeft {
  margin-top: $hz-spacing--xs;

  &::before {
    left: 80px;
  }
}

.hz-zrsp-saveSearchButton {
  text-align: center;

  .hz-zrsp-message {
    display: inline-block;
    margin-top: 24px;
  }

  .hz-zrsp-button {
    display: inline-block;
    margin: 24px;
  }
}

header[data-expanded=true] ~ main {
  margin-top: 148px;

  .hz-Chips--sticky {
    top: 144px !important;
  }
}
.hz-Breadcrumbs {
  margin: $hz-spacing--s 0;
}

.hz-Page-element--breadCrumbAndSaveSearch {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;

  @include hz-breakpoint-xs-up {
    flex-wrap: wrap;
    padding: 0 $hz-spacing--xxxs;
  }

  .hz-Breadcrumbs {
    flex-grow: 1;
    justify-content: flex-start;
  }

  .saveSearchControl {
    display: none;
    flex-grow: 0;
    justify-content: flex-end;
    align-items: center;
    text-align: right;

    @include hz-breakpoint-m-up {
      display: flex;
    }

    p {
      width: 150px;
      @include hz-textStyle--bodySmall;
      color: $hz-color--borderStrong;
    }

    button {
      margin-left: 8px;
    }
  }
}

.hz-Page-element--redirectMessage {
  display: flex;
  justify-content: center;

  .hz-Inpage{
    flex: 0 1 660px;
  }
}

article {
  margin-bottom: 1px;
}

.hz-Page-element {
  &--aside {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    @include hz-breakpoint-xl-up {
      flex: 0 0 25.83333% !important;
      max-width: 25.83333% !important;
    }
  }

  &--main {
    @include hz-breakpoint-xl-up {
      flex: 0 0 74.16667% !important;
      max-width: 74.16667% !important;
    }
  }

  .hz-loading-placeholder {
    min-height: 100vh;
  }
}

// will be fixed in DSDK
.hz-Listing-location {
  text-overflow: initial !important;
  white-space: initial !important;
}
.hz-Listing-group--main {
  word-break: break-word;
  overflow: hidden;
}

#adsense-for-shopping-container-zrp {
  margin-bottom: 20px;
}

//Override header styles when unsupportedBrowserWarning is enabled
#unsupported-browser-warning-banner:not(.hz-hidden)+#__next {
  .hz-Page-body {
    position: relative;
  }
  .u-stickyHeader .hz-Header {
    position: absolute;
  }
}

// to prevent from covering header with an overlay. z-index should be <= z-index of the header
// background should not be the same as for the modal window, coz loading overlay has different meaning
.hz-Page-overlay {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.4);
  z-index: $hz-zIndex--header;

  // To show loading overlay over the mobile filter dialog
  &--mobile-filter-dialog {
    z-index: $hz-zIndex--notifications;
  }
}

.hz-H1Title {
  @include hz-textStyle--bodyLarge;
  padding: $hz-spacing--xs 0;

  h1 {
    @include hz-textStyle--bodyLarge;
    margin: 0;
    line-height: 0;
    padding: 0;
    display: inline-block;
  }

}
