@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.hz-SuggestedSearches {
  margin-top: $hz-spacing--m;
  margin-bottom: $hz-spacing--m;
  width: 100%;

  @include hz-breakpoint-xl-up {
    margin: $hz-spacing--xs 0;
  }

  &-container {
    width: 100%;
    padding: ($hz-spacing--m - 4px);

    @include hz-breakpoint-m-up {
      display: grid;
      grid-template-columns: 120px auto;    
    }
  }

  &-header {
    margin: $hz-spacing--xs;
    display: flex;
    align-items: center;
  }

  &-content {
    white-space: nowrap;
    overflow: auto;
    margin: $hz-spacing--xxs;

    &::-webkit-scrollbar { height: 0; }

    & a{
      margin: 0 4px;
    }
  }

  &-popularKeywordPageLink {
    @include hz-textStyle--bodyRegular;
    background: $hz-color--backgroundLight;
    padding: $hz-spacing--xs $hz-spacing--m;
  }
}
