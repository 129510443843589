@import "~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars";
@import "~@hz-design-system/design-tokens/dist/web/global/ThemeGetters";
@import "~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints";

.faqFooter {
  margin: $hz-spacing--m $hz-spacing--xxxs $hz-spacing--xxl;
  width: 100%;

  .heading {
    text-align: center;
    padding: $hz-spacing--m 0;

    @include hz-breakpoint-m-up {
      display: grid;
      text-align: left;
      grid-template-columns: 0 auto 1fr;
    }

    @include hz-breakpoint-xl-up {
      text-align: center;
      grid-template-columns: 1fr auto 1fr;
    }

    h4 {
      margin: 0 0 $hz-spacing--s 0;
    }

    p {
      color: $hz-color--textSecondary;
      @include hz-textStyle--bodySmall;
    }

    button {
      margin-top: $hz-spacing--xs;
      @include hz-breakpoint-m-up {
        margin: auto 0 0 auto;
      }
    }
  }

  .hz-Card-block {
    padding: $hz-spacing--xxs $hz-spacing--m;
    box-shadow: none;
  }

  .hz-Accordion-accordionItem {
    padding: $hz-spacing--xs 0;
    border-top: 1px solid $hz-color--borderLight;

    &:first-child {
      border-top: 0;
    }

    .hz-AccordionItem-accordionHeader {
      border: 0;
      padding: 0;
    }

    .hz-AccordionHeader-content {
      @include hz-textStyle--bodyLargeStrong;
    }
  }

  .hz-AccordionItem-accordionBody {
    margin-bottom: $hz-spacing--xxs;
    color: $hz-color--textSecondary;
    @include hz-textStyle--bodyLarge;
  }
}

.faqItem {
  &:first-child {
    border: 0;
  }
}
