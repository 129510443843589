@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.dataDrivenContent {
    padding-top: $hz-spacing--m;
    padding-right: $hz-spacing--xxxs;
    padding-left: $hz-spacing--xxxs;
    padding-bottom: $hz-spacing--l;
    display: flex;
    flex-direction: column;
    width: 1472px;
    max-width: 100%;
}
.heading {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 $hz-spacing--s;
}
.disclaimer {
    font-size: $hz-fontSize--bodySmall;
    color: $hz-color--textSecondary;
    padding: $hz-spacing--xxs;
}
.cardsCollection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: $hz-spacing--xs;

}

.leftAlign {
   @include hz-breakpoint-l-up {
       justify-content: flex-start;
    }
}


.card {
    width: 470px;
    margin-top: $hz-spacing--xxs;
    padding: $hz-spacing--xxs $hz-spacing--xs $hz-spacing--xs $hz-spacing--xs;
    box-shadow: none;
    word-break: break-word;

    @include hz-breakpoint-m-up {
        margin: $hz-spacing--xxs;
    }
    
    div {
        padding-top: $hz-spacing--xxs;
    }
    
}
