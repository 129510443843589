@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/ThemeGetters';

.saveSearchControl {
  .hz-Button--primary {
    background-color: $hz-color--signalActionDefault;
    border-color: $hz-color--signalActionHover;
    color: $hz-color--signalActionDefaultInverse;

    .hz-Icon.hz-Icon--textPrimaryInverse {
      filter: invert(99%) sepia(1%) saturate(3036%) hue-rotate(20deg) brightness(111%) contrast(97%);
    }

    &:hover {
      background-color: $hz-color--signalActionHover;
      border-color: $hz-color--signalActionHover;
      color: $hz-color--signalActionDefaultInverse;
    }

    &:disabled {
      // TODO implement disabled button
      background: #e6e4e1;
      box-shadow: none;
      border: none;
      cursor: initial;
    }
  }
}
