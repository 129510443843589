@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.hz-Modal--SellerReviews {
  iframe {
    border: 0;
  }

  .hz-Modal-Content {
    padding: 0;
  }

  &.hz-Modal--l {
    @include hz-breakpoint-s-up {
      max-width: 640px;
      min-width: 440px;
    }
  }
}
