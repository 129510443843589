@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.hz-Listing-Opvalsticker-wrapper {
  &--gallery {
    position: absolute;
    top: 0px;
    left: 0px;
    min-width: 50%;

    .hz-Listing-Opvalsticker {
      background: $hz-color--brandPrimary;
      color: $hz-color--backgroundDefault;
      display: block;
      padding: 2px 5px;
      text-align: center;
      margin-top: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 24px;
      max-width: 100%;
      white-space: nowrap;
      @include hz-textStyle--bodySmall;
    }
  }

  &--list-item {
    display: block;
    margin-top: $hz-spacing--xxxs;

    .hz-Listing-Opvalsticker {
      background: $hz-color--brandPrimary;
      color: $hz-color--backgroundDefault;
      display: block;
      text-align: center;
      margin-top: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
      height: auto;
      padding: $hz-spacing--xxxs $hz-spacing--xxs;
      border-radius: $hz-spacing--xxxs;
      @include hz-textStyle--bodySmallStrong;
  
      @include hz-breakpoint-s-up {
        @include hz-textStyle--bodyRegularStrong;
      }
    }
  }
}
