@import "~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars";
@import "~@hz-design-system/design-tokens/dist/web/global/ThemeGetters";
@import "~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints";


.hz-Listing-details-button {
    color: $hz-color--textSecondary;
    padding-top: $hz-spacing--xxxxs;
    @include hz-textStyle--bodySmall;
  
    @include hz-breakpoint-s-up {
      display: none;
    }
  }