@import "~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars";
@import "~@hz-design-system/design-tokens/dist/web/global/ThemeGetters";
@import "~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints";

.hz-Listing--list-item {
  &.hz-Listing {
    &--hz-Listing--cas {
      background: $hz-color--backgroundDeprecatedSidebar;
    }
  }
}

.hz-Listing-extended-content {
  color: $hz-color--textSecondary;
  display: none;

  &--open {
    padding-bottom: $hz-spacing--s;
    flex-grow: 1;
    display: inline-block;
    @include hz-textStyle--bodySmall;

    @include hz-breakpoint-s-up {
      display: none;
    }

    .hz-Listing-description {
      display: block;
      @include hz-textStyle--bodySmall;
      color: var(--hz-color--textSecondary);
      margin-top: var(--hz-space--xxs);
    }
  }

  summary {
    display: none;
  }
}
