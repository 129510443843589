@import "~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars";
@import "~@hz-design-system/design-tokens/dist/web/global/ThemeGetters";
@import "~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints";

.seller-header-info-free {
  &__mobile-dsa {
    .accordion-header {
      padding: $hz-spacing--xxs;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .joined-since {
      color: $hz-color--textTertiary;
      margin-bottom: 8px;
    }

    .reviews {
      display: flex;
      align-items: center;
    }

    .dialog-link {
      cursor: pointer;
    }

    .item {
      display: flex;
      color: $hz-color--textSecondary;

      .icon {
        margin-right: 8px;
      }
    }

    .company-name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .hz-SaveSellerControl {
      position: relative;
      margin-right: $hz-spacing--xs;
    }

    .accordion-body {
      padding: $hz-spacing--s;
      padding-top: 0;
    }

    .accordion-body-content {
      display: flex;
      flex-direction: column;
      row-gap: $hz-spacing--xs;
    }
  }

  // desktop-dsa, desktop-non-dsa, mobile-non-dsa
  &__others {
    padding: $hz-spacing--xs;
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 32px;

    .reviews-wrapper {
      min-width: 210px;
    }

    .reviews {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .dialog-link {
      cursor: pointer;
    }

    .dsa-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 32px;
      column-gap: 32px;
    }

    .company-name {
      width: 185px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .eu-compliance-item {
      display: flex;
      color: $hz-color--textPrimary;
      width: 160px;

      .icon {
        margin-right: 8px;
      }

      .info-icon {
        cursor: pointer;
      }

      .hz-PopOverContent {
        z-index: 99;
      }

      .hz-PopOverTrigger {
        height: 32px;
      }

      .dsaApprovedPopover {
        width: 196px;
        color: $hz-color--textPrimaryInverse;
      }
    }

    .item {
      display: flex;
      color: $hz-color--textPrimary;

      .icon {
        margin-right: 8px;
      }

      .value {
        color: $hz-color--textSecondary;
      }
    }

    .hz-SaveSellerControl {
      position: relative;
      right: 0;
      bottom: 0;
    }

    .hz-SaveSellerControl {
      margin-left: auto;
    }
  }
}

.seller-header-info-paid {
  overflow: hidden;
  padding: $hz-spacing--xs $hz-spacing--xs $hz-spacing--xs 96px;
  position: relative;
  flex-flow: wrap;

  .dialog-link {
    cursor: pointer;
  }

  .left {
    display: block;
    left: 16px;
    line-height: 24px;
    position: absolute;
    top: 11px;

    .rating-number {
      display: none;
    }

    @include hz-breakpoint-s-up {
      left: 46px;
      text-align: right;
      width: 150px;

      .rating-number {
        display: inline;
      }
    }
  }

  .item {
    display: block;
    color: $hz-color--textTertiary;
    line-height: 24px;
    margin-left: 20px;
  }

  .item + .item {
    margin-top: 6px;
  }

  .hz-SaveSellerControl {
    position: absolute;
    left: 22px;
    bottom: -2px;
  }

  .review-number {
    display: inline-block;
    min-width: 24px;
    text-align: right;
  }

  @include hz-breakpoint-s-up {
    padding: $hz-spacing--xs $hz-spacing--xs $hz-spacing--xs 216px;

    .item {
      margin-right: 20px;
      margin-left: 0;
    }

    .item + .item {
      margin-top: 0;
    }

    .hz-SaveSellerControl {
      bottom: inherit;
      left: inherit;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
