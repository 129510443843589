@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.dac7-promo-banner {
    background-color: $hz-color--signalLinkDefault;
    display: flex;
    align-items: center;
    padding: $hz-spacing--xs;
    border-radius: $hz-spacing--xxxs;
    position: relative;
    margin-bottom: $hz-spacing--xs;
    cursor: pointer;

    @include hz-breakpoint-m-up {
        margin-bottom: 20px;
    }

    .logo {
        margin-right: $hz-spacing--xs;
    }

    .info-block {
        width: 240px;

        @include hz-breakpoint-m-up {
            width: auto;
        }
    }
    
    .title, .description {
        display: block;
        color: $hz-color--textPrimaryInverse;
        .more {
            text-decoration: underline;
        }
    }

    .close-button {
        display: flex;
        justify-content: center;
        position: absolute;
        right: 9px;
        top: 12px;
        cursor: pointer;
        color: $hz-color--textPrimaryInverse;

        @include hz-breakpoint-m-up {
            width: 36px;
            right: 12px;
        }
    }

}