@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

@import '../../styles/fallback-variables';


.lrp-mobile-dialog {
  .hz-Button--primary {
    background-color: $hz-color--signalActionDefault;
    border-color: $hz-color--signalActionHover;
    color: $hz-color--signalActionDefaultInverse;

    .hz-Icon.hz-Icon--textPrimaryInverse {
      filter: invert(99%) sepia(1%) saturate(3036%) hue-rotate(20deg) brightness(111%) contrast(97%);
    }

    &:hover {
      background-color: $hz-color--signalActionHover;
      border-color: $hz-color--signalActionHover;
      color: $hz-color--signalActionDefaultInverse;
    }
  }

  .hz-Modal-Content {
    padding: 0 $hz-spacing--s;
  }
}

.hz-Filters {
  display: none;

  &-content {
    padding: $hz-spacing--s 0;
  }

  &-dialog {
    .hz-Dialog-content {
      position: relative;
      max-height: 100%;
    }
  }

  @include hz-breakpoint-m-up {
    display: block;
    position: relative;
    z-index: 10;
    padding: 0;
  }
}

.with-expandable-layout {
  @include hz-breakpoint-m-up {
    .hz-Filters {
      background: $hz-color--backgroundDefault;
      border-radius: 4px;
      margin-bottom: $hz-spacing--s;
    }
  }

  @include hz-breakpoint-xl-up {
    .hz-Filters {
      margin-right: $hz-spacing--l;
    }
  }

  .hz-Filter-fields {
    .hz-TextField {
      flex: 1 1 auto;

      + .hz-TextField {
        margin-left: $hz-spacing--xs;
      }

      .hz-TextField-formControl {
        max-width: 100%;
        margin: 0;
      }
    }
  }
}

.hz-Inline-input {
  float: left;
  line-height: 1.4em;
  padding: $hz-spacing--xxxs $hz-spacing--xxxs $hz-spacing--xxxs 0;

  input {
    [type='checkbox'],
    [type='radio'] {
      box-sizing: border-box;
      padding: 0;
    }
  }

  + label {
    display: block;
    padding: $hz-spacing--xxxs 0 $hz-spacing--xxxs $hz-spacing--m;

    img,
    span {
      vertical-align: middle;
    }
  }
}

.hz-SelectionGroup {
  .hz-Filter-attribute-text {
    width: 100%;

    @include hz-breakpoint-m-up {
      width: auto;
    }
  }
}

.hz-Filter {
  margin-bottom: $hz-spacing--m;

  .hz-Link {
    line-height: 24px;

    &:hover {
      text-decoration: none;
    }
  }

  .hz-SelectionInput-Body {
    flex-direction: row;
    gap: $hz-spacing--xxxs;
  }

  .hz-Search-submit {
    max-width: 50px;
    padding: 0;
    width: 18%;

    span {
      margin: 0 !important;
    }
  }

  &-attribute {
    @include hz-textStyle--bodyRegular;

    .hz-SelectionInput {
      padding: 0;
    }

    label {
      &, &.hz-SelectionInput-Label {
        font-size: $hz-fontSize--bodyRegular;
        width: 100%;
        cursor: pointer;
        padding: $hz-spacing--xxxxs 0;
        align-items: center;

        &:hover {
          background-color: $hz-color--tabBarSelectedBackground;
        }
      }

      .hz-SelectionInput-InputContainer {
        display: flex;
      }
    }

    .hz-SelectionInput--Radio .hz-SelectionInput-Input {
      margin: 0;
    }

    &-direct-link {
      display: none;
    }

    input {
      margin-right: $hz-spacing--xxs;
    }

    &-counter {
      color: $hz-color--textTertiary;
      padding-left: $hz-spacing--xxxs;
      text-decoration: none;
    }

    &--no-results {
      label,
      label.hz-SelectionInput-Label {
        color: $hz-color--borderStrong;
        cursor: auto;

        &:hover {
          background: none;
        }
      }

      .hz-SelectionInput:hover .hz-SelectionInput-Input {
        border-color: $hz-color--borderStrong;
      }
    }
  }

  &-counter {
    color: $hz-color--textTertiary;
    padding-left: $hz-spacing--xxxs;
    text-decoration: none;
  }

  &-fields {
    overflow: hidden;
    display: flex;

    .hz-TextField-formControl {
      margin: 0 $hz-spacing--xxxs 0 0;
      background: $hz-color--backgroundDefault;
      max-width: 120px;
    }
  }

  &-field-range {

    float: left;
    margin-right: 2%;
    max-width: 120px;
    width: 38%;
  }

  &-heading {
    margin-bottom: $hz-spacing--xxxs;

  }

  .hz-Tree-list {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;

    &-item {
    margin-top: $hz-spacing--xxs;

    &:first-child {
      margin-top: 0;
    }
  }

    &-item--compact {
    margin-top: 0;
  }

    li {
    @include hz-textStyle--bodyRegular;
    color: $hz-color--brandTertiary;
    list-style: none;

    &:hover {
      background-color: $hz-color--backgroundDeprecatedSidebar;
    }
  }

  .hz-Level {
    &-two {
      display: none;
      margin-left: $hz-spacing--m;

      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }

      &--more {
        color: $hz-color--textPrimary;
        cursor: pointer;
        font-style: italic;
        margin-top: $hz-spacing--xxxs;
        padding: $hz-spacing--xxxxs 0 $hz-spacing--xxxs;

        &:hover {
          text-decoration: underline;
        }
      }

      &--selected {
        background-color: $hz-color--borderLight;
      }
    }

    &-one {
      font-weight: 700;
      display: flex;
      align-items: center;

      &--open ~ .hz-Level-two {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--closed ~ .hz-Level-two {
        display: none;
      }

      &--selected {
        background-color: #e5e5e4;;
      }

      &--more {
        color: $hz-color--textPrimary;
        cursor: pointer;
        font-style: italic;
        padding: $hz-spacing--xxxxs 0 $hz-spacing--xxxs;

        &:hover {
          text-decoration: underline;
        }
      }

      a {
        display: block;
        padding: $hz-spacing--xxxs 0;
        width: 100%;
      }
    }
  }

    .hz-State-icon {
      cursor: pointer;
      float: left;
      padding: $hz-spacing--xxxs $hz-spacing--xxs $hz-spacing--xxxs 0;
    }
  }
}

.hz-Filter-distance-form {
  display: flex;

  .hz-Filter-distance-fields {
    width: 100%;

    .hz-SearchForm-postcode {
      margin-bottom: 8px;
    }


    @include hz-breakpoint-m-up {
      width: 70%;
      margin-right: 8px;
    }
  }

  .hz-Filter-distance-submit {
    @include hz-breakpoint-m-up {
      width: 30%;
      align-self: center;

      .hz-Search-submit {
        width: 100% !important;
      }
    }
  }
}

.hz-TextAttribute-content {
  overflow-y: auto;
  box-shadow: none;
  padding-top: $hz-spacing--s;
  
  @include hz-breakpoint-m-up {
    height: 300px;
  }
}

.textAttribute-searchInput {
  width: 100%;

  .hz-TextField-formControl {
    border: none;
    width: 100%;
  }
}

.textAttribute-desktopButton {
  display: none;
  @include hz-breakpoint-m-up {
    display: block;
  }
}

.textAttribute-mobileButton {
  display: block;

  @include hz-breakpoint-m-up {
    display: none;
  }
}

.textAttribute-showMore {
  cursor: pointer;
  display: block;
  margin-top: $hz-spacing--xxxs;
  color: $hz-color--signalLinkDefault;
}

.category-name--selected {
  font-weight: bold;
}

.hz-ButtonsBlock--footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;

  .hz-Button {
    border-radius: 0;
  }
}

.hz-Button--secondary {
  /* TODO update after hz-web-ui gets disabled state for button */
  &:disabled {
    cursor: initial;
    background-color: $hz-color--buttonSecondaryHoverInverse;
  }
}

.hz-AccordionCustom {
  @include hz-breakpoint-up(m) {
    padding: $hz-spacing--xs;
  }

  + .hz-AccordionCustom {
    border-top: 1px solid $hz-color--borderLight;
  }

  .hz-AccordionItem-accordionHeader {
    border: none;
    background-color: transparent;
  }

  .hz-AccordionItem-accordionBody {
    padding: $hz-spacing--xs 0;
  }
}

.hz-Filter-fields--dropdown {
  gap: $hz-spacing--xxs;
}

.hz-Filter-dropdown {
  flex: 1 1 50%;

  .hz-Dropdown-formControl {
    width: 100%;
  }
}

.hz-Filter-attributeButtons {
  display: flex;
  flex-wrap: wrap;
  gap: $hz-spacing--xxs;
}

.hz-Filter-toggleGroup {
  margin-top: hz-spacing(xxxs);
  display: flex;
  align-items: center;

  .hz-Button,
  .hz-Button:hover {
    @include hz-textStyle(body-large);
    flex-grow: 1;
    color: hz-color(text-primary);
    background-color: hz-color(background-default);
    border-color: hz-color(border-medium);
  }

  .hz-Button {
    @include hz-breakpoint-up(s) {
      padding: 0 hz-spacing(xxxs);
    }

    @media (hover: hover) {
      &:hover {
        background-color: hz-color(button-secondary-hover--inverse);
        border-color: hz-color(button-secondary-default);
        color: hz-color(text-primary);
      }
    }
  }

  .hz-Button:nth-child(1) {
    border-radius: hz-spacing(xxxs) 0 0 hz-spacing(xxxs);
    border-right: 0;
  }

  .hz-Button:nth-child(2) {
    border-radius: 0;
    border-right: 0;
  }

  .hz-Button:nth-child(3) {
    border-radius: 0 hz-spacing(xxxs) hz-spacing(xxxs) 0;
  }

  .hz-Button + .hz-Button.isActive,
  .hz-Button.isActive + .hz-Button {
    border-left-color: hz-color(button-secondary-default);
  }

  &:active,
  .isActive,
  .isActive:hover {
    background-color: hz-color(button-secondary-hover--inverse);
    border-color: hz-color(button-secondary-default);
    color: hz-color(text-primary);
  }
}

.hz-Filter-attribute-button.hz-Link.hz-Link--isolated:hover {
  text-decoration: none;
}

.hz-Filter-attribute-button {
  flex: 1 1 50%;
  max-width: calc(50% - 4px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $hz-color--borderMedium;
  border-radius: $hz-spacing--xxxs;
  box-shadow: inset 0 0 1px transparent;
  box-sizing: border-box;
  padding: $hz-spacing--xxs;

  @include hz-breakpoint-m-up {
    flex-basis: 100%;
    max-width: 100%;
    flex-direction: row;
    padding: $hz-spacing--xxxs;
  }

  @include hz-breakpoint-l-up {
    padding: $hz-spacing--xxs;
    overflow: hidden;
  }

  @include hz-breakpoint-xl-up {
    flex-basis: 50%;
    max-width: calc(50% - $hz-spacing--xxxs);
    flex-direction: column;
  }

  .buttonText {
    text-align: center;

    @include hz-breakpoint-m-up {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
    }

    @include hz-breakpoint-l-up {
      display: block;
    }

    @include hz-breakpoint-xl-up {
      text-align: center;
    }
  }

  .buttonLabel {
    width: 100%;
  }

  &--selected {
    border-color: $hz-color--signalActionDefault;
    box-shadow: inset 0 0 0 1px $hz-color--signalActionDefault;
  }

  &:hover {
    border-color: $hz-color--signalActionDefault;
    background-color: $hz-color--tabBarSelectedBackground;
  }

  .hz-Icon {
    height: 42px;
    width: 68px;
    background-size: auto $hz-spacing--xxl;

    @include hz-breakpoint-m-up {
      height: $hz-spacing--l;
      width: 34px;
      background-size: auto $hz-spacing--l;
      flex-shrink: 0;
      margin-right: $hz-spacing--xxxs;
    }

    @include hz-breakpoint-l-up {
      height: $hz-spacing--l;
      width: 50px;
      background-size: auto $hz-spacing--xl;
    }

    @include hz-breakpoint-xl-up {
      height: 42px;
      width: 68px;
      background-size: auto $hz-spacing--xxl;
    }
  }
}

.PriceTypeModal p {
  margin-bottom: $hz-spacing--s;
}

.hz-Filter-heading {
  color: $hz-color--textSecondary;

  .ModalTrigger {
    margin-left: $hz-spacing--xxxs;
  }
}

.textAttribute-modal .hz-Modal-Content {
  padding-bottom: 0;
}

.hz-Filter .infoTooltip {
  visibility: hidden;
  display: block;
  width: 33%;
  height: 0;

  + .hz-PopOverContent {
    margin-left: $hz-spacing--xxs;
  }
}
