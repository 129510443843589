@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';

.ndfc-wrapper {
  display: flex;
  padding: $hz-spacing--xs;
  border: 1px solid $hz-color--borderMedium;
  border-radius: $hz-spacing--xxxs;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $hz-spacing--s;

  .close {
    cursor: pointer;
  }
}
