@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.zrp-search-helper {
  &-container {
    flex-direction: column;
    align-items: flex-start!important;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);

    @include hz-breakpoint-m-up {
      padding: 40px 100px!important;
    }

  }
  &-title {
    @include hz-textStyle--title4;
    padding-bottom: 20px;
  }
  &-description {
    padding-bottom: 10px;
  }
  &-input-group {
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;

    i {
      transform: translate3d(8px, 4px, 0);
      z-index: 1;
    }

    .hz-TextField {
      background: $hz-color--backgroundDefault;
      flex: 1;
      margin-left: -24px;

      input {
        padding-left: 24px;
      }

      &-formControl {
        margin-top: 0;
      }
    }

    .hz-Button {
      margin-top: 10px;
      width: 100%;
    }

    @include hz-breakpoint-m-up {
      display: flex!important;

      .hz-Button {
        margin-top: 0;
        width: initial;
        margin-left: 10px;
      }
    }
  }
}

.hz-Alert {
  background: $hz-color--backgroundDeprecatedSidebar;
  display: flex;
  padding: $hz-spacing--s $hz-spacing--s $hz-spacing--s $hz-spacing--m;
  position: relative;
}
