@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.hz-SSOIZeroResultsBox-wrap {
  width: 100%;
}

.hz-SSOIZeroResultsBox-container {
  max-width: 350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.hz-SSOIZeroResultsBox-title {
  margin-bottom: $hz-spacing--xxs;
  @include hz-textStyle--title3;
}

.hz-SSOIZeroResultsBox-subtitle {
  margin-bottom: ($hz-spacing--m - 4px);
  @include hz-textStyle--bodyRegular;
}
