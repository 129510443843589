@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.hz-Listing--list-item-cars {
  padding: hz-spacing(xxs);
  margin: hz-spacing(xxs) 0;
  position: relative;

  @include hz-breakpoint-up(m) {
    padding: hz-spacing(xs);
    margin: hz-spacing(xs) 0;
  }

  @include hz-breakpoint-up(l) {
    padding: hz-spacing(s) hz-spacing(m) hz-spacing(s) hz-spacing(s);
  }

  .hz-Listing-coverLink {
    display: flex;
    text-decoration: none;
  }

  .hz-Listing-description {
    display: none;
  }

  .hz-Listing-image {
    margin-right: hz-spacing(xs);
    flex: 0 0 120px;
    max-width: 120px;

    @include hz-breakpoint-up(m) {
      flex: 0 0 176px;
      max-width: 176px;
      position: relative;
    }

    @include hz-breakpoint-up(l) {
      margin-right: hz-spacing(m);
      flex: 0 0 250px;
      max-width: 250px;
    }

    figure {
      margin: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .hz-Listing-image-item--main {
      width: 100%;
      height: 90px;

      @include hz-breakpoint-up(m) {
        height: 132px;
      }

      @include hz-breakpoint-up(l) {
        height: 188px;
      }
    }

    .hz-Listing-sub-images {
      display: flex;
      height: 43px;
      margin-top: hz-spacing(xxxs);

      @include hz-breakpoint-up(m) {
        height: 65px;
      }

      @include hz-breakpoint-up(l) {
        height: 93px;
      }

      .hz-Listing-image-item {
        flex: 0 0 50%;
        padding-right: hz-spacing(xxxxs);
      }

      .hz-Listing-image-item + .hz-Listing-image-item {
        padding: 0 0 0 hz-spacing(xxxxs);
      }
    }

    img {
      border-radius: 2px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .hz-Listing-badges {
    margin-top: hz-spacing(xxxs);

    @include hz-breakpoint-up(m) {
      margin-top: 0;
      position: absolute;
      top: hz-spacing(xxxs);
      left: hz-spacing(xxxs);
      right: hz-spacing(xxxs);
    }

    @include hz-breakpoint-up(l) {
      top: hz-spacing(xxs);
      left: hz-spacing(xxs);
      right: hz-spacing(xxs);
    }
  }

  .hz-Listing-badge {
    justify-content: center;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;

    @include hz-breakpoint-up(m) {
      max-width: 100%;
      width: auto;
      text-align: left;
    }
  }

  .hz-Listing-group {
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 38px;

    @include hz-breakpoint-up(s) {
      margin-bottom: 32px;
    }

    @include hz-breakpoint-up(m) {
      margin-bottom: 53px;
      height: auto;
      min-height: 100%;
    }

    @include hz-breakpoint-up(l) {
      margin-bottom: 57px;
    }
  }

  .hz-Listing-headings {
    margin-bottom: hz-spacing(xxs);

    &.withFavoriting {

      @include hz-breakpoint-up(l) {
        margin-right: hz-spacing(xl);
      }
    }

    @include hz-breakpoint-up(s) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: hz-spacing(s);
    }

    @include hz-breakpoint-up(l) {
      align-items: baseline;
      display: flex;
      margin: hz-spacing(xxxs) 0 hz-spacing(s);
    }
  }

  .hz-Listing-title {
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: hz-spacing(xxs);
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;

    &.withFavoriting {
      margin-right: 36px;

      @include hz-breakpoint-up(l) {
      margin-right: hz-spacing(s);
      }
    }

    @include hz-breakpoint-up(l) {
      margin: 0 hz-spacing(s) 0 0;
      display: inline;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1 1 auto;
    }

    span {
      display: inline;
    }
  }

  .hz-Listing-trim {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include hz-breakpoint-up(s) {
      display: inline;
      font-size: 16px;
      line-height: 22px;
      margin-left: hz-spacing(xxxs);
    }
  }

  .hz-Listing-price-extended-details {
    display: flex;
    margin: 0;
    align-items: baseline;
    justify-content: space-between;
    @include hz-textStyle--bodyRegular;

    @include hz-breakpoint-up(l) {
      margin-bottom: 0;
    }

    .hz-Title {
      display: inline;
    }
  }

  .hz-Listing-attributes {
    flex-wrap: nowrap;
    margin-bottom: hz-spacing(xxs);
    margin-top: 0;

    @include hz-breakpoint-up(s) {
      margin-bottom: hz-spacing(s);
    }

    .hz-Attribute {
      @include hz-textStyle--bodyRegularStrong;
      padding: 0;
      display: none;
      white-space: nowrap;

      &:nth-child(-n+2) {
        display: inline-block;
      }

      @include hz-breakpoint-up(s) {
        &:nth-child(-n+3) {
          display: inline-block;
        }

        &:nth-child(3) {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      @include hz-breakpoint-up(m) {
        &:nth-child(-n+4) {
          display: inline-block;
        }
      }

      @include hz-breakpoint-up(l) {
        display: inline-block;
      }
    }

    .hz-Attribute + .hz-Attribute {
      margin-left: hz-spacing(s);
    }
  }

  .hz-Listing-sellerDistance,
  .hz-Listing-seller-external-link {
    &:before {
      content: '·';
      margin: 0 hz-spacing(xxxs);
    }
  }

  .hz-Listing-seller-external-link {
    display: none;

    @include hz-breakpoint-up(s) {
      display: inline-block;
      vertical-align: middle;
    }

    @include hz-breakpoint-up(m) {
      order: 2;
    }
  }

  .hz-Listing-attribute-options {
    display: none;

    @include hz-breakpoint-up(l) {
      display: block;
      margin-bottom: hz-spacing(s);
    }
  }
  .hz-Listing-extended-attributes {

    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    .hz-Attribute--extended {
      @include hz-textStyle--bodySmall;
      color: var(--hz-color--textSecondary);
    }

    .hz-Attribute:not(:last-child):after {
      content: "\2C\A0";
    }
  }
  .hz-Listing-highlights {
    margin-bottom: hz-spacing(xxxs);
  }

  .hz-Listing-highlight {
    margin: 0 hz-spacing(xxxs) hz-spacing(xxxs) 0;
  }

  .hz-Listing-footer {
    display: flex;
    position: absolute;
    left: 136px;
    right: hz-spacing(xxs);
    bottom: hz-spacing(xxs);
    @include hz-textStyle--bodySmall;

    @include hz-breakpoint-up(s) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-top: 1px solid $hz-color--borderLight;
      padding-top: hz-spacing(xxs);
      margin-top: hz-spacing(xxxs);
      flex: 0 0 24px;
    }

    @include hz-breakpoint-up(m) {
      left: 200px;
      right: hz-spacing(xs);
      bottom: hz-spacing(xs);
    }

    @include hz-breakpoint-up(l) {
      left: 290px;
      right: hz-spacing(m);
      bottom: hz-spacing(s);
      @include hz-textStyle--bodyRegular;
    }
  }

  .hz-Listing-seller,
  .hz-Listing-meta {
    flex: 1 1 auto;
    flex-direction: column;
  }

  .hz-Listing-seller {
    min-width: 0;
    overflow: hidden;

    @include hz-breakpoint-up(m) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include hz-breakpoint-up(l) {
      margin-top: hz-spacing(xxxs);
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .hz-Listing-seller-image {
    display: none;

    @include hz-breakpoint-up(m) {
      display: inline;
      vertical-align: middle;
      margin-right: hz-spacing(xxs);
      height: 48px;
      width: 48px;
      border-radius: 2px;
      object-fit: contain;
      background-size: 10000px;
      background-position: 10px 10px;
    }

    @include hz-breakpoint-up(l) {
      max-height: 48px;
    }
  }

  .hz-Listing-sellerName {
    margin-bottom: hz-spacing(xxxs);

    @include hz-breakpoint-up(s) {
      margin-bottom: 0;
    }

    @include hz-breakpoint-up(m) {
      order: 1;
      margin-bottom: hz-spacing(xxs);
    }
  }

  .hz-Listing-seller-name {
    color: $hz-color--textPrimary;

    &:hover {
      color: $hz-color--signalActionDefault;
    }
  }

  .hz-Listing-sellerLocation {
    @include hz-breakpoint-up(s) {
      &:before {
        content: '·';
        margin: 0 hz-spacing(xxxs);
      }
    }

    @include hz-breakpoint-up(m) {
      flex: 1 0 100%;
      order: 3;
      align-self: flex-end;

      &:before {
        display: none;
      }
    }
  }

  .hz-Listing-priority {
    margin-bottom: hz-spacing(xxxs);

    @include hz-breakpoint-up(s) {
      margin-bottom: 0;
    }
  }

  .hz-Listing-sellerName,
  .hz-Listing-sellerLocation,
  .hz-Listing-priority,
  .hz-Listing-listingDate {
    display: block;

    @include hz-breakpoint-up(s) {
      display: inline-block;
    }
  }

  .hz-Listing-sellerName .hz-Text,
  .hz-Listing-sellerLocation,
  .hz-Listing-priority,
  .hz-Listing-listingDate {
    white-space: nowrap;
  }

  .hz-Listing-priority + .hz-Listing-listingDate {
    width: auto;

    @include hz-breakpoint-up(s) {
      &:before {
        content: '·';
        margin: 0 hz-spacing(xxxs);
      }
    }
  }

  .hz-Listing-meta {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    margin-left: hz-spacing(s);

    @include hz-breakpoint-up(s) {
      display: block;
    }

    @include hz-breakpoint-up(m) {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
  }

  .hz-Listing-action {
    position: absolute;
    top: 0;
    right: 0;

    @include hz-breakpoint-up(l) {
      top: 2px;
    }
  }

  + .hz-Banner {
    #banner-top-dt,
    #banner-rubrieks-dt {
      background-color: transparent;
      box-shadow: none;
      margin: hz-spacing(xs) 0;
    }
  }
}
