@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '../../../styles/fallback-variables';

.hz-Listing {
  &-seller-name-container {
    display: none;
    width: 100%;
    @include hz-textStyle--bodySmall;

    @include hz-breakpoint-m-up {
      @include hz-textStyle--bodyRegular;
      display: flex;
    }
    a {
      font-weight: bold;
    }
  }

  &-seller-name {
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.has-tooltip {
  position: relative;
  .is-tooltip {
    @include hz-textStyle--bodySmall;
    position: absolute;
    top: 30px;
    left: -290px;
    width: 320px;
    border: 1px solid $hz-color--borderMedium;
    background: $hz-color--backgroundDefault;
    padding: $hz-spacing--xs;
    opacity: 0;
    z-index: 2;
    visibility: hidden;
    color: $hz-color--textPrimary;
    &:before {
      content: '';
      position: absolute;
      right: 9px;
      top: -9px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $hz-color--borderMedium;
      clear: both;
    }
    &:after {
      content: '';
      position: absolute;
      right: 10px;
      top: -8px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 8px solid $hz-color--backgroundDefault;
      clear: both;
    }
    .tooltip-first-block {
      font-size: 14px;
    }
    .divider {
      margin: $hz-spacing--xs 0 $hz-spacing--xxxs 0;
      height: 1px;
      background-color: #ccc;
    }
    a:visited {
      color: $hz-color--signalActionDefault;
    }
  }
  &:hover {
    flex: 1;
    .is-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}
