@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.smart-banner-large {
  border-radius: 8px 8px 0 0;
  border-top: solid #e5e5e4 1px;
  z-index: 99;
  padding: $hz-spacing--s;
  position: fixed;
  bottom: 0;

  .app-download-image {
    width: 100%;
  }

  .button-block {
    padding: 0;
    margin-top: 12px;
  }
}