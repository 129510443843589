@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.hz-Banner {
  &--sticky-left {
    flex: 1;
    @include hz-breakpoint-xl-up {
      min-width: 300px;
      width: 100%;
    }

    div {
      position: sticky;
      top: 122px;
    }

    &-top {
      max-height: 3000px;
    }
  }

  &--sticky-right {
    flex: 1;
    @include hz-breakpoint-xl-up {
      min-width: 120px;
      text-align: left;
    }

    div {
      position: sticky;
      top: 122px;
      min-height: 20px;
    }
  }

  &--fluid {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  &--inline-gv {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 100%;

    @include hz-breakpoint-m-up {
      width: auto;
      max-width: 64%;
      min-width: 64%;
    }

    @include hz-breakpoint-xl-up {
      max-width: 50%;
      min-width: 50%;
    }

    > div {
      margin: $hz-spacing--xxxs;
    }
  }
}

.banner-right-placeholder {
  height: 850px;
}

#banner-top-dt {
  text-align: center;
  min-height: 0;

  &.soiBanner.loading {
    min-height: 250px;
  }

  &.loading {
    @include hz-breakpoint-m-up {
      min-height: 250px;
    }
  }

  &.mobileOnly {
    @include hz-breakpoint-m-up {
      display: none;
      min-height: 0;
    }
  }

  @include hz-breakpoint-m-up {
    min-height: 90px;
  }

  @include hz-breakpoint-xl-up {
    margin: $hz-spacing--xxs 0;
    min-height: 0;
  }
}


.bannerContainerLoading {
  @include hz-breakpoint-m-up {
    min-height: 250px!important;
  }
}

#banner-bottom-dt-l {
  margin: $hz-spacing--xxs 0;
  text-align: center;
  min-height: 250px;

  @include hz-breakpoint-xl-up {
    margin: $hz-spacing--xs 0;
  }
}

#banner-rubrieks-dt {
  background: $hz-color--backgroundDefault;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 1px;
  min-height: 250px;
}

#banner-rubrieks-dt, #banner-bottom-dt-l, #banner-top-dt {
  align-items: center;
  display: flex;
  flex: 1 0 100%;
  justify-content: center;
}

.hz-Listings {
  #banner-top-dt {
    @include hz-breakpoint-xl-up {
      background: $hz-color--backgroundDefault;
    }
  }
}

.hz-adsense-header {
  padding: 12px 0;
}
