@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '../../../styles/fallback-variables';
@import 'ListingListItem.Classes';
@import 'ListingGalleryItem.Classes';

.hz-Listings {
  padding-inline-start: 0;
  list-style: none;
  padding: 0;
  margin: 0;

  &--gallery-view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__admarktTitle {
    padding: 10px 0;
    color: $hz-color--textTertiary;
    @include hz-textStyle--bodySmall;
  }
}

.hz-Listing {
  &--gallery-item {
    padding: $hz-spacing--xxs;
  }

  &-priority {
    color: $hz-color--signalSuccessDefault;
  }
}

.hz-Listing-group {
  @include hz-breakpoint-m-up {
    height: 100%;
  }
}

.hz-Listing-group--title-description-attributes {
  .hz-Listing-description {
    text-align: left;
  }
}

.hz-Listing-price {
  margin-bottom: $hz-spacing--s;
  @include hz-textStyle--title5;
  color: $hz-color--textPrimary;
}

.hz-Listing-date {
  text-align: right;
}

@include hz-breakpoint-m-up {
  .hz-Listing-price,
  .hz-Listing-location,
  .hz-Listing-date {
    order: inherit;
  }
}

.hz-Attribute + .hz-Attribute {
  margin-top: 0;
}

.reportingFeedback {
  margin-bottom: 10px;
}

.hz-Listing--cas {
  background-color: $hz-color--backgroundDeprecatedSidebar;
}

.hz-Listing--gallery-item {
  &.hz-Listing--casGallery {
    background-color: transparent;
    max-width: 23%;
    min-width: 23%;
    box-shadow: none;

    &:hover {
      background-color: $hz-color--backgroundDefault;
      -webkit-transform: none;
      transition: none;
    }
  }
}

/* Adding this to the selector above breaks the color */
.hz-Listing--casGallery__goToLink {
  color: $hz-color--signalSuccessDefault;
  font-weight: 400;
  font-size: .688em;
}

.hz-Listings__container--cas, .hz-Listings__container--casGallery {
  content-visibility: auto;
}