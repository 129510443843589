.hz-SvgAsset.hz-Asset--fluid.EnergyLabelmp {
  height: 20px;
  width: 60px;
  background-repeat: no-repeat;
}

.hz-SvgAsset.hz-Asset--fluid.EnergyLabeltwh {
  height: 20px;
  width: 78px;
  background-repeat: no-repeat;
}
