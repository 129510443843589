@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

header[data-expanded=false] ~ main {
  margin-top: 122px;
}

.error-page {
  .hz-Page-body {
    flex-wrap: nowrap;
    justify-content: space-between;
    background: $hz-color--backgroundDefault;
  }
}

.hz-ErrorPage-messages {
  text-align: center;
  margin: 50px auto;

  .hz-textStyle--bodyLarge {
    margin-top: 30px;
    @include hz-textStyle--bodyLarge;
  }
  .hz-textStyle--title2 {
    @include hz-textStyle--title2;
  }
}
