@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

#smart-banner {
  display: none;
  overflow: hidden;
  height: 70px;
  position: relative;
  width: 100%;
  font-family: 'Helvetica Neue',sans-serif;
  background: $hz-color--backgroundLight;
  z-index: $hz-zIndex--header;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;

  .sb-close {
    position: absolute;
    left: 5px;
    top: 23px;
  }

  .sb-close:active {
    font-size: 13px;
  }

  .sb-icon {
    position: absolute;
    left: 38px;
    top: 12px;
    display: block;
    width: 57px;
    height: 57px;
  }

  .no-icon {

    .sb-icon {
      display: none;
    }

    .sb-info {
      left: 44px;
    }
  }

  .sb-info {
    position: absolute;
    left: 106px;
    top: 6px;
    width: 44%;
    font-size: 11px;
    line-height: 1em;
    font-weight: bold;
    color: $hz-color--textPrimary;

    strong {
      display: block;
      font-size: 17px;
      line-height: 10px;
      font-weight: bold;
    }

    .sb-subtitle {
      display: block;

      @include hz-breakpoint-m-up {
        display: none;
      }
    }

    em {
      font-style: normal;
      text-transform: uppercase;
    }
  }

  .sb-button {
    right: 20px;
    top: 24px;

    @include hz-breakpoint-m-up {
      right: 10px;
      top: 24px;
      position: absolute;
    }
  }
}

#ios-smart-banner {
  display: none;
  overflow: hidden;
}
