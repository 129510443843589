@import '~@hz-design-system/design-tokens/dist/web/global/ThemeCssVars';
@import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '../../styles/fallback-variables';

.hz-PaginationControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $hz-spacing--m;
  @include hz-textStyle--bodyRegular;

  @include hz-breakpoint-xl-up {
    margin: $hz-spacing--xs 0;
  }

  /* TODO move to hz-web-ui */
  .hz-Button--primary {
    background-color: $hz-color--signalActionDefault;
    border-color: $hz-color--signalActionHover;
    color: $hz-color--signalActionDefaultInverse;

    .hz-Icon.hz-Icon--textPrimaryInverse {
      filter: invert(99%) sepia(1%) saturate(3036%) hue-rotate(20deg) brightness(111%) contrast(97%);
    }

    &:hover {
      background-color: $hz-color--signalActionHover;
      border-color: $hz-color--signalActionHover;
      color: $hz-color--signalActionDefaultInverse;
    }
  }

  &-pagination {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @include hz-breakpoint-s-up {
      width: auto;
      justify-content: start;
    }
    &-amountOfPages {
      display: block;
      @include hz-breakpoint-s-up {
        display: none;
      }
    }

    & > a > span {
      padding: 0;
    }

    &-pageList {
      display: none;

      & > a {
        cursor: pointer;
      }

      @include hz-breakpoint-s-up {
        display: flex;
      }
      & > * {
        padding: $hz-spacing--xxxs;
        margin-right: $hz-spacing--xxxs;
      }
    }
  }

  .saveSearchControl {
    display: none;
    flex-grow: 0;
    justify-content: flex-end;
    align-items: center;
    text-align: right;

    @include hz-breakpoint-m-up {
      display: flex;
    }

    p {
      width: 150px;
      color: $hz-color--borderStrong;
      @include hz-textStyle--bodySmall;
    }

    button {
      margin-left: 8px;
    }
  }

  &-pageSize {
    display: none;
    align-items: inherit;

    @include hz-breakpoint-s-up {
      display: inherit;
    }

    &-list {
      display: inherit;
      padding-inline-start: 0;

      & li:first-child {
        border-left: none;
      }

      & li {
        margin-left: $hz-spacing--xxxs;
        padding-left: $hz-spacing--xxxs;
        list-style: none;
        border-left: 1px solid $hz-color--borderMedium;

        & a,
        span {
          display: inline-block;
          padding-left: $hz-spacing--xxs;
          padding-right: $hz-spacing--xxs;
          line-height: 32px;
        }
      }

      &--selected {}
    }
  }
}

.hz-PaginationControls--new {
  margin: $hz-spacing--m 0;
  justify-content: center;

  @include hz-breakpoint-xl-up {
    margin: $hz-spacing--m 0;
  }

  .hz-PaginationControls-pagination-pageList {
    & > * {
      padding: $hz-spacing--xs;
      margin: 0 $hz-spacing--xxs;
    }
  }
}
